import { animate, state, style, transition, trigger } from '@angular/animations';

export const expandDetailAnimation = trigger('detailExpand', [
  state('collapsed,void', style({ height: '0px', minHeight: '0', padding: '0' })),
  state('expanded', style({ height: '*', padding: '5px' })),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

export const openSliderAnimation = trigger('openSlider', [
  state('ouvert', style({ width: '70%', minWidth: '70%' })),
  state('fermer', style({ width: '0', minWidth: '0' })),
  transition('ouvert <=> fermer', [animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
]);

export const voirDetailAnimation = trigger('voirDetail', [
  state('true', style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })),
  state('false', style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '0' })),
  transition('false => true', [animate('0.4s ease-out')]),
]);

export const mouseEnterAnimation = trigger('mouseEnter', [
  state('oui', style({ maxWidth: '800px', minWidth: '40%' })),
  state('non', style({ width: '0px', minWidth: '0' })),
  transition('oui <=> non', animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
