import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DocAction, PdfActon } from '../redditions/global/global-reddition.interface';

@Injectable({
  providedIn: 'root',
})
export class FactureService {
  constructor(private _httpClient: HttpClient) {}

  redditionAction = signal<DocAction | undefined>(undefined);
  acompteAction = signal<DocAction | undefined>(undefined);

  blob = signal<Blob | undefined>(undefined);
  filename = signal<string>('');
  voirPdf = signal<boolean>(false);

  private acompteAction$ = toSignal(
    toObservable(this.acompteAction).pipe(
      filter(Boolean),
      switchMap(x => {
        return this.telechargerJustificationAcompte$(x.id).pipe(
          map(y => {
            this.acompteAction.set(undefined);
            this.docAction(y, x);
          }),
        );
      }),
    ),
  );

  private redditionAction$ = toSignal(
    toObservable(this.redditionAction).pipe(
      filter(Boolean),
      switchMap(x => {
        return this.telechargerFactureReddition$(x.id).pipe(
          map(y => {
            this.redditionAction.set(undefined);
            this.docAction(y, x);
          }),
        );
      }),
    ),
  );

  private docAction(reponse: HttpResponse<Blob>, docaction: DocAction) {
    const content = reponse.headers.get('Content-Disposition');
    const fileName = content ? content.split('filename=')[1].split(';')[0] : 'document.pdf';
    if (docaction.action === PdfActon.telecharger) {
      const blob = reponse.body!;
      this.telechargerDocBlob(blob, fileName);
    } else if (docaction.action === PdfActon.visionner) {
      this.blob.set(new Blob([reponse.body!], { type: 'application/pdf' }));
      this.filename.set(fileName);
      this.voirPdf.set(true);
    }
  }

  telechargerDocBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  private telechargerFactureReddition$(id: string): Observable<HttpResponse<Blob>> {
    const url = `${environment.backendUrl}facturation/facture/reddition/${id}`;
    return this._httpClient.get(url, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  private telechargerJustificationAcompte$(idAcompte: string): Observable<HttpResponse<Blob>> {
    const url = `${environment.backendUrl}facturation/justificatif-acompte/acompte-reddition/${idAcompte}`;
    return this._httpClient.get(url, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
